var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"goods-item"},[_c('div',{staticClass:"goods-item-detail",on:{"mouseenter":function($event){return _vm.handleMouse(true)},"mouseleave":function($event){return _vm.handleMouse(false)}}},[_c('router-link',{attrs:{"to":{
                    name: _vm.routerPath,
                    query: {
                        id: _vm.goodsItem.id,
						type: _vm.selectedStyle,
						datum: _vm.pageData
                    }
                }}},[_c('el-image',{staticClass:"goods-item-detail-pic",attrs:{"src":_vm.goodsItem.img,"fit":"scale-down"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('div',{staticClass:"image-slot"},[_c('i',{staticClass:"el-icon-loading"})])]},proxy:true},{key:"error",fn:function(){return [_c('div',{staticClass:"image-slot"},[_c('i',{staticClass:"el-icon-picture-outline"})])]},proxy:true}])})],1),_c('div',{class:[
                    'goods-item-detail-owner',
                {
                    'owner-show': _vm.ownerShow
                }]},[_c('el-image',{staticClass:"goods-item-detail-owner-avatar clearfix",attrs:{"src":_vm.goodsItem.owner_avatar,"fit":"scale-down"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('div',{staticClass:"image-slot"},[_c('i',{staticClass:"el-icon-loading"})])]},proxy:true},{key:"error",fn:function(){return [_c('div',{staticClass:"image-slot"},[_c('i',{staticClass:"el-icon-picture-outline"})])]},proxy:true}])}),_c('div',{staticClass:"goods-item-detail-owner-name"},[_c('span',[_vm._v(_vm._s(_vm.goodsItem.owner_name))]),(true)?_c('img',{attrs:{"src":require('@/assets/images/lensman/@1x/icon_male.png'),"alt":""}}):_c('img',{attrs:{"src":require('@/assets/images/lensman/@1x/icon_girl.png'),"alt":""}})]),_c('div',{staticClass:"goods-item-detail-owner-router"},[_c('router-link',{attrs:{"to":{
                            name: _vm.routerPath,
                            query: {
                                id: _vm.goodsItem.id,
								type: _vm.selectedStyle,
								datum: _vm.pageData,
								
                            }
                        }}},[_vm._v(" 立即查看 ")])],1),_c('div',{staticClass:"goods-item-detail-owner-data"},[_c('div',[_vm._v("售出："+_vm._s(_vm.goodsItem.sell)+"份")]),_c('div',[_vm._v("发布日期："+_vm._s(_vm.goodsItem.release))])])],1)],1),_c('div',{staticClass:"goods-item-title"},[_vm._v(" "+_vm._s(_vm.goodsItem.name)+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }