<template>
    <div class="goods-item">
        <!-- detail -->
        <div class="goods-item-detail"
            @mouseenter="handleMouse(true)"
            @mouseleave="handleMouse(false)">
            <!-- picture -->
            <router-link 
                :to="{
                    name: routerPath,
                    query: {
                        id: goodsItem.id,
						type: selectedStyle,
						datum: pageData
                    }
                }">
                <el-image
                    :src="goodsItem.img"
                    fit="scale-down"
                    class="goods-item-detail-pic">
                    <!-- 加载中样式 -->
                    <template v-slot:placeholder>
                        <div class="image-slot">
                            <i class="el-icon-loading"></i>
                        </div>
                    </template>
                    <!-- 加载失败样式 -->
                    <template v-slot:error>
                        <div class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </template>
                </el-image>
            </router-link>
            <!-- owner -->
            <div :class="[
                    'goods-item-detail-owner',
                {
                    'owner-show': ownerShow
                }]">
                <!-- avatar -->
                <el-image
                    :src="goodsItem.owner_avatar"
                    fit="scale-down"
                    class="goods-item-detail-owner-avatar clearfix">
                    <!-- 加载中样式 -->
                    <template v-slot:placeholder>
                        <div class="image-slot">
                            <i class="el-icon-loading"></i>
                        </div>
                    </template>
                    <!-- 加载失败样式 -->
                    <template v-slot:error>
                        <div class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </template>
                </el-image>
                <!-- name -->
                <div class="goods-item-detail-owner-name">
                    <span>{{goodsItem.owner_name}}</span>
                    <img :src="require('@/assets/images/lensman/@1x/icon_male.png')" alt="" v-if="true">
                    <img :src="require('@/assets/images/lensman/@1x/icon_girl.png')" alt="" v-else>
                </div>
                <!-- router -->
                <div class="goods-item-detail-owner-router">
                    <router-link 
                        :to="{
                            name: routerPath,
                            query: {
                                id: goodsItem.id,
								type: selectedStyle,
								datum: pageData,
								
                            }
                        }">
                        立即查看
                    </router-link>
                </div>
                <!-- data -->
                <div class="goods-item-detail-owner-data">
                    <!-- sale -->
                    <div>售出：{{goodsItem.sell}}份</div>
                    <!-- online -->
                    <div>发布日期：{{goodsItem.release}}</div>
                </div>
            </div>
        </div>
        <!-- title -->
        <div class="goods-item-title">
            {{goodsItem.name}}
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                ownerShow: false
            }
        },
        props: {
            routerPath: String,
            goodsItem: {
                type: Object,
                default: {}
            },
			pageData: {
			    type: Object,
			    default:()=>{ return {} }
			},
			selectedStyle: {
			    type: String ,
			    default: ()=>{}
			}
        },
        methods: {
            handleMouse(val){
                this.ownerShow = val;
            }
        },
    }
</script>

<style scoped>
/* detail */
.goods-item-detail{
    position: relative;
    width: 100%;
}
.goods-item-detail::before{
    display: block;
    content: "";
    padding-top: 150%;
}
/* picture */
.goods-item-detail-pic{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* border: 1px solid #F2F6FC; */
    /* box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1); */
    background-color: #F2F6FC;
}
/* owner */
.goods-item-detail-owner{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 34%;
    background-color: rgba(31, 62, 170,.8);
    opacity: 0;
    z-index: -1;
    transition: all .2s linear;
}
.owner-show{
    opacity: 1;
    z-index: 10;
}
/* avatar */
.goods-item-detail-owner-avatar{
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 60px;
    border-radius: 30px;
}
/* name */
.goods-item-detail-owner-name{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    color: #fff;
}
/* router */
.goods-item-detail-owner-router{
    margin-top: 10px;
    text-align: center;
}
.goods-item-detail-owner-router > a{
    display: inline-block;
    padding: 2px 16px;
    background-color: #fed86b;
    border-radius: 30px;
    font-weight: bold;
    color: var(--color);
}
/* data */
.goods-item-detail-owner-data{
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 6px;
    z-index: -1;
}
.goods-item-detail-owner-data > div{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    color: #fff;
}
/* title */
.goods-item-title{
    margin-top: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>